import axios from "axios";
import { JWT } from '../../config';
import { AppConstant } from "../reducer";
import { history } from "../../history";
import { versionSort, formatBytes, getVersion, getApiUrl } from "../../lib/function";
import { DoDB } from "../../lib/ddb";
import { getEnv } from "../../lib/function";

const GetAppItemByType = async (name) => {
    const items = await DoDB("query",  {
        TableName: 'rayrnd-applications',
        KeyConditionExpression: "#name = :name and #type = :type",
        ExpressionAttributeNames: { '#name': 'name', '#type': 'type' },
        ExpressionAttributeValues: { ':name': name,  ':type' : 'app' }
    });
    return items?.length > 0 ? items[0] : {};
}

const GetAppInfoOnly = async (appCode, lang) => {
    const list = await DoDB("query",  {
        TableName: 'rayrnd-applications',
        IndexName: 'type-name-index',
        KeyConditionExpression: '#name=:name and #type=:type',
        ExpressionAttributeNames: { '#name': 'name', '#type': 'type' },
        ExpressionAttributeValues: { ':name': appCode,  ':type' : 'app' }
    })
    const data = {
        Items: list,
    };
    if (data.Items.length === 0) {
        history.push("/noapps");
        return
    }
    return data.Items.length > 0 ? data.Items[0] : {};
}

const GetAppInfo = (appCode, lang) => async (dispatch) => {
    let allowTypePrefixList = [
        "d:tc:",
        "t:",
        "v:",
    ];
    let list = await DoDB("query",  {
        TableName: 'rayrnd-applications',
        KeyConditionExpression: '#name=:name and #type = :type',
        ExpressionAttributeNames: { '#name': 'name', "#type": "type" },
        ExpressionAttributeValues: { ':name': appCode, ":type": "app" },
    });
    if (list.length === 0) {
        history.push("/noapps");
        return
    }
    const _app = list.find(x => x.type === "app");
    if (_app?.last_published_version) {
        const _list = await DoDB("query", {
            TableName: 'rayrnd-applications',
            KeyConditionExpression: '#name=:name and #type = :type',
            FilterExpression: "#is_active = :true",
            ExpressionAttributeNames: { '#name': 'name', "#type": "type", "#is_active": "is_active" },
            ExpressionAttributeValues: { ':name': appCode, ":type": `v:production:${_app?.last_published_version}`, ":true": true },
        });
        if (_list.length > 0) {
            list = [...list, ..._list];
            allowTypePrefixList = allowTypePrefixList.filter(x => x !== "v:");
        }
    }
    for (const typePrefix of allowTypePrefixList) {
        const _list = await DoDB("query",  {
            TableName: 'rayrnd-applications',
            KeyConditionExpression: '#name=:name and begins_with(#type, :type)',
            ExpressionAttributeNames: { '#name': 'name', "#type": "type" },
            ExpressionAttributeValues: { ':name': appCode, ":type": typePrefix },
        });
        list = [...list, ..._list];
    }
    // const list = await DoDB("query",  {
    //     TableName: 'rayrnd-applications',
    //     KeyConditionExpression: '#name=:name',
    //     ExpressionAttributeNames: { '#name': 'name' },
    //     ExpressionAttributeValues: { ':name': appCode }
    // })
    const data = {
        Items: list,
    };
    const appTermRoots = list.filter(x => x.type.startsWith("d:tc:root:") && x.isPublished);
    const terms = {};
    for (const termRoot of appTermRoots) {
        const _term = { 
            code: termRoot.code, 
            version: termRoot.currentVersion,
            published: termRoot.currentPublished,
        };
        const langSk = termRoot.lastDoc.find(x => x.code === lang)?.type;
        if (!langSk) {
            continue;
        }
        const _termContent = list.find(x => x.type === langSk);
        _term.doc = _termContent;
        terms[termRoot.code] = _term;
    }

    // const data = await dc.query(params).promise();

    if (data.Items.length === 0) {
        history.push("/noapps");
        return
    }

    let homepageContents = data.Items.filter(x => x.type.startsWith("t:header") && x.isVisible);
    homepageContents = versionSort(homepageContents);

    // const templateVersion = data.Items.find(x => x.type.indexOf('t:header') > -1 && x.isVisible)?.version;
    const templateVersion = homepageContents[0]?.version;

    const flist = data.Items.filter(x => x.type.indexOf('t:body:' + templateVersion + ':feature') > -1 && x.type.substr(-2) == lang);
    const newflist = flist.map(x => {
        const ntype = x.type.split(":");
        ntype.pop(); ntype.push("header");
        const lbl = data.Items.find(d => d.type === ntype.join(":"))?.label;
        // return {...x, head : lbl === "--HEAD--" }
        return {...x, head : lbl.startsWith("--HEAD--") }
    });
    
    let tempInfo = {};
    if (templateVersion) {
        tempInfo = {
            feature: newflist,
            headerInfo: data.Items.find(x => x.type === 't:header:' + templateVersion),
            movie: data.Items.find(x => x.type === 't:body:' + templateVersion + ':movie:' + lang.toLowerCase())?.movies,
            intro: data.Items.find(x => x.type === 't:body:' + templateVersion + ':intro:' + lang.toLowerCase())?.introduction,
            faq: data.Items.find(x => x.type === 't:body:' + templateVersion + ':faq:header')?.faqs
                .sort((a, b) => { return a.order - b.order })
                .map(x => {
                    const filtered = data.Items.find(y => y.type === 't:body:' + templateVersion + ':faq:' + x.code + ':' + lang.toLowerCase());
                    return { q: filtered?.question, a: filtered?.answer }
                }),
            imageInfo: data.Items.find(x => x.type === 't:body:' + templateVersion + ':image:' + lang.toLowerCase())
        }
    }

    const lastestVersion = versionSort(data.Items.filter(x => x.type.indexOf('v:production') == 0 && x.is_active && x.version !== undefined))[0];
    const version = lastestVersion?.version;
    const fileSize = lastestVersion?.full_file?.file_size ? formatBytes(lastestVersion?.full_file?.file_size) : null;
    const basicInfo = data.Items.find(x => x.type === 'app');
    const htmlManual = data.Items.filter(x => x.type.indexOf(':manual:' + lang.toLowerCase()) > -1).length > 0;
    const hasInstall = (!basicInfo.rayteams_apps || (basicInfo.rayteams_apps && basicInfo?.showHomeDown)) ? (lastestVersion?.full_file?.direct_link ? true : false) : false;
    const result = {
        appCode,
        terms,
        licenseId: basicInfo.licenseId,
        appTitle: basicInfo.title,
        appIcon: tempInfo?.headerInfo?.icon?.direct_link,
        category: basicInfo.category,
        updated: basicInfo.updated,
        published: lastestVersion?.published ?? lastestVersion?.updated ?? basicInfo.updated,
        description: tempInfo?.headerInfo?.desc,
        bgImage: tempInfo?.headerInfo?.bgImage?.direct_link,
        provider: tempInfo?.headerInfo?.provider,
        providerLink: tempInfo?.headerInfo?.providerLink,
        privacyLink: tempInfo?.headerInfo?.privacyLink,
        languages: tempInfo?.headerInfo?.langs,
        tags: tempInfo?.headerInfo?.tags,
        appAuth: tempInfo?.headerInfo?.authList,
        screenshot: tempInfo?.imageInfo?.images?.map((x, idx) => {
            return {
                seq: idx,
                url: x.direct_link,
                is_main: tempInfo?.imageInfo.mainImage.direct_link === x.direct_link ? true : false
            }
        }),
        hasInstall: hasInstall,
        hasManual: basicInfo?.manuals?.length > 0,
        htmlManual,
        intro: tempInfo?.intro,
        faq: tempInfo?.faq,
        movie: tempInfo?.movie,
        version,
        fileSize,
        feature: tempInfo?.feature ? tempInfo?.feature?.map(x => {
            return {
                head: x.head,
                title: x.title,
                contents: x.content,
                mediaType: x.mediaType,
                mediaLink: (x.mediaType === 'image' && x.image.direct_link) || (x.mediaType === 'movie' && x.movie)
            }
        }) : [],
        appDownloadInfo: hasInstall ? {
            type: 'install',
            version: lastestVersion?.version,
            url: lastestVersion?.full_file?.direct_link,
            fileSize: lastestVersion?.full_file?.file_size,
            updated: lastestVersion?.published ?? lastestVersion?.updated,
        } : {},
        manualDownloadInfo: basicInfo.manuals?.map(x => {
            return {
                seq: x.sequence,
                title: x.label,
                url: x.file.direct_link,
                fileSize: x.file.file_size,
                visible: x.visible
            }
        }),
        isForceUpdate: basicInfo?.update_force,
        _basicInfo: basicInfo,
    };

    dispatch({ type: AppConstant.GET_APP_INFO, data: result });
}

const GetReleaseNotesRC = (appCode, lang, version) => async (dispatch) => {
    const list = await DoDB("query",  {
        TableName: 'rayrnd-applications',
        KeyConditionExpression: "#name = :name and begins_with(#type, :type)",
        ExpressionAttributeNames: { '#name': 'name', '#type': 'type' },
        ExpressionAttributeValues: {
            ':name': appCode,
            ':type': 'v:production:' + version
        }
    })
    const baseInfo = list.filter(x => x?.version);
    let filtered = list.filter(x => x.type.indexOf('d_lang') > -1 && x.type.split(':')[x.type.split(':').length - 1] === lang);
    if (filtered.length === 0)
        filtered = list.filter(x => x.type.indexOf('d_lang') > -1);

    dispatch({
        type: AppConstant.GET_RELEASE_NOTES_RC,
        data: versionSort(filtered.map(x => {
            return {
                appCode,
                version: getVersion(x.type.split(':')[2]),
                lang,
                title: x.title,
                contents: x.desc,
                updated: x.updated,
                released: baseInfo.find(y => y.version === x.type.split(':')[2])?.published,
                download_link: baseInfo.find(y => y.version === x.type.split(':')[2])?.full_file?.direct_link
            }
        }))
    })
}

const GetReleaseNotes = (appCode, lang) => async (dispatch) => {
    const appInfos = await DoDB("query",  {
        TableName: 'rayrnd-applications',
        KeyConditionExpression: "#name = :name and #type = :type",
        ExpressionAttributeNames: { '#name': 'name', '#type': 'type' },
        ExpressionAttributeValues: { ':name': appCode, ':type': 'app' }
    })
    const appInfo = appInfos.length > 0 ? appInfos[0] : {};
    const downloadable = appInfo?.rayteams_apps ? !!appInfo.showHomeDown : true;

    const list = await DoDB("query",  {
        TableName: 'rayrnd-applications',
        KeyConditionExpression: "#name = :name and begins_with(#type, :type)",
        FilterExpression: "#is_active = :true",
        ExpressionAttributeNames: { '#name': 'name', '#type': 'type', '#is_active': 'is_active' },
        ExpressionAttributeValues: { ':name': appCode, ':type': 'v:production:', ':true': true }
    })
    const baseInfo = list.filter(x => x?.version);
    let releaseNotes = [];
    for (const versionItem of baseInfo) {
        let releaseNote = list.find(x => x.type === `v:production:${versionItem.version}:d_lang:${lang}`);
        releaseNote = releaseNote || list.find(x => x.type === `v:production:${versionItem.version}:d_lang:en`);
        if (!releaseNote) {
            continue;
        }

        // 데이터를 Action 레벨에서 변경하는건 지양해야함. 단, 코드 작업의 시간이 없음으로 기존 스타일을 따라감
        const item = {
            appCode,
            version: versionItem.version,
            lang,
            downloadable,
            title: releaseNote?.title || null,
            contents: releaseNote?.desc || null,
            updated: releaseNote?.updated || versionItem.updated,
            released: versionItem.published,
            download_link: versionItem?.full_file?.direct_link
        };
        releaseNotes.push(item);
    }
    releaseNotes = versionSort(releaseNotes);
    dispatch({ type: AppConstant.GET_RELEASE_NOTES, data: releaseNotes });

    // let filtered = list.filter(x => x.type.indexOf('d_lang') > -1 && x.type.split(':')[x.type.split(':').length - 1] === lang);
    // if (filtered.length === 0)
    //     filtered = list.filter(x => x.type.indexOf('d_lang') > -1 && x.type.split(':')[x.type.split(':').length - 1] === "en");

    // dispatch({
    //     type: AppConstant.GET_RELEASE_NOTES,
    //     data: versionSort(filtered.map(x => {
    //         return {
    //             appCode,
    //             version: getVersion(x.type.split(':')[2]),
    //             lang,
    //             downloadable : list.find(l => l.type === "v:production:" + x.type.split(':')[2]) ? true : false,
    //             title: x.title,
    //             contents: x.desc,
    //             updated: x.updated,
    //             released: baseInfo.find(y => y.version === x.type.split(':')[2])?.published,
    //             download_link: baseInfo.find(y => y.version === x.type.split(':')[2])?.full_file.direct_link
    //         }
    //     }))
    // })
}

const GetManual = (appCode, lang) => async (dispatch) => {
    if (!lang) {
        return;
    }
    let versions = await DoDB("query",  {
        TableName: 'rayrnd-applications',
        KeyConditionExpression: "#name=:name and begins_with(#type, :bw)",
        FilterExpression: "isVisible = :true",
        ExpressionAttributeNames: { '#name': 'name', '#type': 'type' },
        ExpressionAttributeValues: {
            ':name': appCode,
            ':bw': 't:header:',
            ":true": true,
        }
    })
    versions = versionSort(versions);
    const lastVersionHeader = versions[0]
    if (!lastVersionHeader || !lastVersionHeader?.version) {
        return;
    }

    let list = await DoDB("query",  {
        TableName: 'rayrnd-applications',
        KeyConditionExpression: "#name=:name and begins_with(#type, :bw)",
        ExpressionAttributeNames: { '#name': 'name', '#type': 'type' },
        ExpressionAttributeValues: {
            ':name': appCode,
            ':bw': 't:body:' + lastVersionHeader.version + ":manual:",
        }
    })
    if (list.length > 0) {
        const contents = list.find(x => x.type.indexOf(':header') === -1 && x.type.split(':')[x.type.split(':').length - 1] === lang);
        const orderData = list.find(x => x.type.indexOf('header') > -1)?.features;
        let result = contents?.features && Object.entries(contents?.features).map(x => {
            return {
                code: x[0],
                title: x[1].title,
                contents: x[1].content,
                intro: x[1].intro || x[1].title,
                order: orderData?.find(y => y.code === x[0])?.order || -1
            }
        });
        result = result.filter(x => x.order !== -1);
        dispatch({
            type: AppConstant.GET_MANUAL, data: result?.sort((a, b) => {
                if (a.order > b.order) return 1;
                if (a.order === b.order) return 0;
                if (a.order < b.order) return -1;
            })
        })
    }
}

const GetAppList = (lang) => async (dispatch) => {
    const list = await DoDB("scan",  {
        TableName: "rayrnd-applications",
        FilterExpression: "#type=:type and #is_active=:t and #isdev=:n and #showHome=:t",
        ExpressionAttributeNames: { "#type": "type", "#is_active": "is_active", "#isdev": "isdev", "#showHome": "showHome" },
        ExpressionAttributeValues: { ":type": "app", ":t": true, ":n": "N" }
    })

    const data = {
        Items: list,
    };
    if (data.Items.length > 0) {
        const appIcons = await GetAppIcons(data.Items.map(x => { return { appCode: x.name } }));
        const result = data.Items.map(x => {
            return {
                app_icon: appIcons.find(y => y.appCode === x.name)?.appIcon,
                category: x.category,
                appCode: x.name,
                appName: x.title,
                desc: x.desc,
                _app_info: x,
            }
        })

        dispatch({ type: AppConstant.GET_APP_LIST, data: result });
    }
}

const GetFeaturedApps = (apps) => async (dispatch) => {
    const str_apps = apps.map(x => { return (':' + x.appCode.toLowerCase()) }).join(',');
    const params = {
        TableName: 'rayrnd-applications',
        FilterExpression: "#name in (" + str_apps + ") and begins_with(#type, :type) and #isVisible=:true",
        ExpressionAttributeNames: { '#name': 'name', '#type': 'type', '#isVisible': 'isVisible' },
        ExpressionAttributeValues: { ':type': 't:header', ':true': true }
    }
    apps.forEach(x => {
        params.ExpressionAttributeValues[':' + x.appCode.toLowerCase()] = x.appCode;
    });
    const list = await DoDB("scan",  params);
    const appTitles = await GetAppTitles(apps);
    const data = {
        Items: list,
    };
    if (data.Items.length > 0) {
        const result = data.Items.map(x => {
            return {
                seq: apps.find(y => y.appCode === x.name).seq,
                app_icon: x.icon?.direct_link,
                appCode: x.name,
                appName: appTitles.find(y => y.appCode === x.name).appTitle,
            }
        }).sort((a, b) => {
            if (a.seq > b.seq) return 1;
            if (a.seq === b.seq) return 0;
            if (a.seq < b.seq) return -1;
        });

        dispatch({ type: AppConstant.GET_FEATURED_APP_LIST, data: result });
    }
}

const GetAppTitles = async (apps) => {
    const list = await DoDB("query",  {
        TableName: "rayrnd-applications",
        IndexName: "type-name-index",
        KeyConditionExpression: "#type = :type",
        ExpressionAttributeNames: { "#type": "type" },
        ExpressionAttributeValues: { ":type": "app" },
    })
    const appNames = apps.map(x => x.appCode);
    return list.filter(x => appNames.includes(x.name)).map(x => ({ ...x, appCode: x.name, appTitle: x.title }));

    // const dc = new AWS.DynamoDB.DocumentClient();
    // const params = {
    //     TableName: 'rayrnd-applications',
    //     FilterExpression: '',
    //     ExpressionAttributeNames: { '#name': 'name', '#type': 'type' },
    //     ExpressionAttributeValues: { ':type': 'app', }
    // };

    // params.FilterExpression = `#name in (${apps.map(x => { return (':' + x.appCode.toLowerCase()) }).join(',')}) and #type=:type`;
    // apps.forEach(x => {
    //     params.ExpressionAttributeValues[':' + x.appCode.toLowerCase()] = x.appCode;
    // });
    // const list = await ScanDdb(params, dc);
    // const appTitles = {
    //     Items: list,
    // };
    // return appTitles.Items.map(x => { return { appCode: x.name, appTitle: x.title } });
}

const GetAppIcons = async (apps) => {
    const str_apps = apps.map(x => { return (':' + x.appCode.toLowerCase()) }).join(',');
    const params = {
        TableName: 'rayrnd-applications',
        FilterExpression: "#name in (" + str_apps + ") and begins_with(#type, :type) and #isVisible=:true",
        ExpressionAttributeNames: { '#name': 'name', '#type': 'type', '#isVisible': 'isVisible' },
        ExpressionAttributeValues: { ':type': 't:header', ':true': true }
    }
    apps.forEach(x => {
        params.ExpressionAttributeValues[':' + x.appCode.toLowerCase()] = x.appCode;
    });
    const list = await DoDB("scan", params)
    const appIcons = {
        Items: list,
    };
    return appIcons.Items.map(x => { return { appCode: x.name, appIcon: x.icon.direct_link } });
}

const CheckAppExists = async (appCode) => {
    const list = await DoDB("query",  {
        TableName: 'rayrnd-applications',
        KeyConditionExpression: '#name=:name',
        ExpressionAttributeNames: { '#name': 'name' },
        ExpressionAttributeValues: { ':name': appCode }
    })
    const data = { Items: list };
    return data.Items.length == 0 ? false : true;
}

const GetAppProducts = async (licenseId) => {
	const API_URL = getEnv() === "production" ? 
        "https://api-manager-license.rayteams.com" : 
        "https://api-manager-license.raydevelop.com";
    const user = JSON.parse(window?.localStorage?.getItem("user"));
    const ipApi = await GetCountryCodeByIP();
    //const ipApi = "JP";
    const group = {
        _id: user?.groupId,
        countryCode: ipApi == "JP" ? "JP" : user?.countryCode,
    };
    if (!group?.countryCode) {
        const GROUP_API_URL = getApiUrl('group');
        const ret = await axios.get(GROUP_API_URL + '/group/' + user.groupId, JWT());
        if (ret?.data?.data?.countryCode) {
            group.countryCode = ret.data.data.countryCode;
        }
    }
    const formData = { data: { group } };
	const ret = await axios.post(API_URL + '/products/apps/' + licenseId, formData, JWT());
    // console.log("0000");
    // console.log(ret);
    // console.log("0000");
	if (ret.data.status === 'success') {
		// console.log(ret.data.data);
		return ret.data.data;
	}
	return null;
};

const GetAppDocs = () => async (dispatch) => {
    const API_URL = getEnv() === "production" ? 
    "https://api-rsp-application.rayteams.com" : 
    "https://api-rsp-application.raydevelop.com"
    const formData = {
        data: {
            match: false,
            filters: "isPublished = :isPublished",
            values: {
                ":isPublished": true
            }
        }
    };
    const paramId = "d:tc:root";
    const ret = await axios.post(API_URL + '/client/app/RayLink/type/' + paramId, formData, JWT());
    if (ret.data.status === 'success') {
        //GET_BAADPA_INFO
        dispatch({ type: AppConstant.GET_BAADPA_INFOS, data: ret.data });
    } else {
        dispatch({ type: AppConstant.GET_BAADPA_INFOS, data: [] });
    }
};

const GetAppDoc = (paramId) => async(dispatch) => {
    const API_URL = getEnv() === "production" ? 
    "https://api-rsp-application.rayteams.com" : 
    "https://api-rsp-application.raydevelop.com"
    const formData = {
        data: {
            match: false,
            filters: "isPublished = :isPublished",
            values: {
                ":isPublished": true
            }
        }
    };
    const ret = await axios.post(API_URL + '/client/app/RayLink/type/' + paramId, formData, JWT());
    if (ret.data.status === 'success') {
        dispatch({ type: AppConstant.GET_BAADPA_INFO, data: ret.data });
    } else {
        dispatch({ type: AppConstant.GET_BAADPA_INFO, data: {} });
    }
}

const GetCountryCodeByIP = async() => {
    try {
        const instance = axios.create();
        instance.defaults.timeout = 2500;
        const response = await instance.get('https://raymedical-ip-check.sungwon-bang.workers.dev/api/getccbyip', {
            timeout: 5000
        });

        //const response = await axios.get('https://raymedical-ip-check.sungwon-bang.workers.dev/api/getccbyip');
        // console.log("getCountryCode = : ", response);
        if (!response) {
            console.log("[getCountryCode response error]");
            return "";
        }
        return response.data;  // 국가 코드를 반환
    } catch (error) {
        console.log("[getCountryCode Error] : ", error);
        return "";
    }
};

export const AppAction = {
    GetAppInfoOnly,
    GetAppInfo,
    GetManual,
    GetReleaseNotes,
    GetReleaseNotesRC,
    GetAppList,
    GetFeaturedApps,
    CheckAppExists,
    GetAppProducts,
    GetAppItemByType,
    GetCountryCodeByIP,
    GetAppDocs,
    GetAppDoc
}
